import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const BedTypes = (
  {
    initialValues,
    className,
    name,
    setValues,
    values,
    showDropdown,
    AllBedType,
    setFieldValue,
    distinctValue = false,
    errors,
    error,
    touched,
    bedDataRef
  },
  ref
) => {
  const [inputCount, setInputCount] = useState(
    initialValues?.bedCounts?.length
  );
  const [bedTypeCounts, setBedCounts] = useState(initialValues?.bedCounts);

  const [selectedBedTypes, setSelectedBedTypes] = useState(
    initialValues?.bedTypeId
  );

  const [isDropdownSelected, setIsDropdownSelected] = useState(false);

  // availableBedTypes?.splice(selectedBedTypes,1 )
  // const getAvailableOptions = (currentIndex) => {
  //   if (distinctValue) {
  //     return AllBedType?.data?.data?.filter(
  //       (item) =>
  //         !selectedBedTypes.includes(`${item.bed_type_id}`) ||
  //         selectedBedTypes.indexOf(item.bed_type_id) === currentIndex
  //     );
  //   } else {
  //     // return AllBedType?.data?.data
  //     return AllBedType?.data?.data?.filter(
  //       (item) =>
  //        !selectedBedTypes.includes(`${item.bed_type_id}`) ||
  //       !selectedBedTypes.indexOf(`${values?.bedTypeId?.[currentIndex]}`) === item.bed_type_id
  //     );
  //     // .filter((item)=>item.bed_type_id===values.bedTypeId[currentIndex]);
  //   }
  // };
  const getAvailableOptions = (currentIndex, values, selectedBedTypes, AllBedType) => {

    if (distinctValue) {
        return AllBedType?.data?.data?.filter(
            (item) =>
                !selectedBedTypes.includes(`${+item.bed_type_id}`) ||
                selectedBedTypes.indexOf(+item.bed_type_id) === currentIndex
        );
    } else {
     
        const selectedBedTypeIds = Array.isArray(values?.bedTypeId) ? values.bedTypeId : [];       
        return (         
        AllBedType?.data?.data?.filter(
            (item) =>
          
                !selectedBedTypeIds?.includes(+item?.bed_type_id) ||
                (Array.isArray(selectedBedTypes) && selectedBedTypes.indexOf(+item.bed_type_id) === currentIndex)
                // selectedBedTypes.indexOf(+item.bed_type_id) === currentIndex// Filter out selected bed type IDs
                
                )
               
          );
    }
};





  // const handleAddClick = () => {
  //   // if (isDropdownSelected) {
  //   setInputCount((prevCount) => prevCount + 1);
  //   setBedCounts((prevCounts) => [...prevCounts, "1"]); 
  //   // }else {
  //   //   toast.dismiss();
  //   //   toast.error(
  //   //     "Please select a value in the current dropdown before adding a new one."
  //   //   );
  //   // }
  // };


  let addBtnDisabled= true;
  const handleAddClick = (e) => {
    e.preventDefault();
    if (inputCount < AllBedType?.data?.data?.length) {
      setInputCount((prevCount) => prevCount + 1);
      setBedCounts((prevCounts) => [...prevCounts, "1"]);
    } else {
      addBtnDisabled = false;
      toast.dismiss();
      toast.error(`You can only select up to ${AllBedType?.data?.data?.length} values.`);
    }
  };
  
  useEffect(() => {
    setFieldValue("bedTypeId", selectedBedTypes);
    setFieldValue("bedCounts", bedTypeCounts);
  }, [selectedBedTypes]);

  const handleRemoveClick = (index) => {
    setInputCount((prevCount) => prevCount - 1);
    setBedCounts((prevCounts) => prevCounts.filter((_, i) => i !== index)); 
    setSelectedBedTypes((prevSelectedBedTypes) => {
      const newSelectedBedTypes = [...prevSelectedBedTypes];
      newSelectedBedTypes.splice(index, 1); // Remove the item at the specified index
      return newSelectedBedTypes;
  });
    updateValues(
      bedTypeCounts.filter((_, i) => i !== +index),
      selectedBedTypes.filter((_, i) => i !== +index)
    );
  };

  const bedCountIncrementBtn = (index) => {
    const newAdultCounts = [...bedTypeCounts];
    newAdultCounts[index] = String(+newAdultCounts[index] + 1);
    setBedCounts(newAdultCounts);
    updateValues(selectedBedTypes, newAdultCounts); // Update values state
  };

  const bedCountDecrementBtn = (index) => {
    if (+bedTypeCounts[index] > 1) {
      const newAdultCounts = [...bedTypeCounts];
      newAdultCounts[index] = String(+newAdultCounts[index] - 1);
      setBedCounts(newAdultCounts);
      updateValues(selectedBedTypes, newAdultCounts); // Update values state
    }
  };

  const updateValues = (newSelectedBedType, newBedCounts) => {
    // Construct new values object with updated counts

    // const updatedValues = newSelectedBedType?.map((TypeId, index) => ({
    //   bedTypeId: TypeId,
    //   bedCounts: newBedCounts[index],
    // }));
    const updatedValues = {
      ...values,
      bedTypeId: newSelectedBedType,
      bedCounts: newBedCounts,
    };

    // setFieldValue("bedTypeId", updatedValues);
    setValues(updatedValues);
  };

  // Function to handle change in bed type selection
  const handleChangeBedTypeChanges = (event, index) => {
    const selectedBedTypeId = event.target.value;
    // Update selected bed type based on index
    setSelectedBedTypes((prevSelectedBedTypes) => {
      const newSelectedBedTypes = [...prevSelectedBedTypes];
      newSelectedBedTypes[index] = +selectedBedTypeId;
      updateValues(+newSelectedBedTypes, 1); // Update only selected bed types
      return newSelectedBedTypes;
    });
    const isAnySelected = selectedBedTypeId !== "";
    setIsDropdownSelected(isAnySelected);
  };


  return (
    <>
      {/* {showDropdown && ( */}
      <>
        {Array.from({ length: inputCount }, (_, index) => (
          <div
            className="booking-form-input guests-input d-flex align-items-center w-100 position-relative gap-4 mb-3"
            key={index}
          >
            <div className="row w-100 align-items-center">
              <div className="col-xl-3 col-md-6">
              <label className="form-label w-100">
                  <i className="fa-solid fa-bed me-2"></i>Bed{" "}
                  <span className="mandatory">*</span>
                </label>
                <select
                ref={bedDataRef}
                  className="form-control form-select py-3 shadow-none px-4"
                  name="bedTypeId"
                  onChange={(e) => handleChangeBedTypeChanges(e, index)}
                  value={values?.bedTypeId?.[index] || []}
                >
                  <option value="">---Select---</option>
                  {/* {getAvailableOptions(index)?.map((item) => (
                    <option key={item.bed_type_id}  value={item.bed_type_id}>
                      {item.bed_type}
                    </option>
                  ))} */}
                  
                  {getAvailableOptions(
                    index,
                    values,
                    selectedBedTypes,
                    AllBedType
                  )?.map((item) => (
                   
                    <option key={item.bed_type_id} value={item.bed_type_id}>
                      {item.bed_type}
                      
                    </option>
                  ))}
                </select>
              </div>

              {/* <Dropdown.Toggle
                  name="guests-btn"
                  className="form-control shadow-none guest-form-control pe-5 "
                  id="guests-input-btn"
                >
                  {adultCounts[index]} Adult, {childrenCounts[index]} Children
                </Dropdown.Toggle> */}
                <div className="col-xl-3 col-md-6 mt-md-0 mt-4">
                <label className="form-label">
                  <i className="fa-solid fa-bed me-2"></i>Bed Count{" "}
                  <span className="mandatory">*</span>
                </label>
                  <div className="d-flex align-items-center">
                  <div className="guest-dropdown-item guest-dropdown bed-count-col position-relative pe-1">
                    {/* <label>Bed Count</label> */}
                    <div className="guest-card d-flex align-items-center">
                      <span
                        className="guests-input__ctrl minus"
                        onClick={() => bedCountDecrementBtn(index)}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </span>
                      <span className="guests-input__value text-center">
                        <span>{bedTypeCounts[index]}</span>
                      </span>
                      <span
                        className="guests-input__ctrl plus"
                        onClick={() => bedCountIncrementBtn(index)}
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </span>
                    </div>
                  </div>                 

                  {index === inputCount - 1 && (
                    <div className="add-more-blocks text-end p-0">
                      <button
                        disabled={!getAvailableOptions(inputCount - 1, values, selectedBedTypes, AllBedType)?.length > 0}
                        onClick={(e)=>handleAddClick(e)}
                        className="btn btn-primary btn-theme fw-semibold text-uppercase"
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  )}
                  {index !== inputCount - 1 && (
                    <div className="add-more-blocks text-end mb-2">
                      <a
                        onClick={() => handleRemoveClick(index)}
                        className="btn btn-outline-danger text-uppercase"
                      >
                        <i className="fa fa-minus"></i>
                      </a>
                    </div>
                  )}
                  </div>
              </div>
              {/* <Dropdown.Menu className="guest-dropdown mw-100 mt-2">
                <div className="row">
                  <div className="mb-4">
                    <div
                      className="guests-input_options"
                      id="guests-input-options"
                    >
                      <div className="guest-dropdown-item">
                        <label>Adult</label>
                        <div className="guest-card d-flex align-items-center">
                          <span
                            className="guests-input__ctrl minus"
                            onClick={() => bedCountDecrementBtn(index)}
                          >
                            <FontAwesomeIcon icon={faAngleLeft} />
                          </span>
                          <span className="guests-input__value">
                            <span>{adultCounts[index]}</span>
                          </span>
                          <span
                            className="guests-input__ctrl plus"
                            onClick={() => bedCountIncrementBtn(index)}
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu> */}
            </div>
            
          </div>
        ))}
        {errors.bedTypeId && touched.bedTypeId ? (
          <p className="form-error text-danger">{errors.bedTypeId}</p>
        ) : error.bedTypeId ? (
          <p className="form-error text-danger">{error.bedTypeId}</p>
        ) : null}
      </>
      {/* )} */}
    </>
  );
};

export default BedTypes;
