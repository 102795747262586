import React from "react";
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify';

import * as dataquery from "../dataService/DataService";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';

const DeleteAwardsModal = ({ row, show, handleClose, rowData, rowNameData, selectedWebsiteId }) => {

    const queryClient = useQueryClient();
    const handleArchive = (data) => {
        deleteAwardRecord.mutate({ data, websiteIds: selectedWebsiteId });
        handleClose();
    };
    const deleteAwardRecord = useMutation(dataquery.deleteAward, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            toast.dismiss();
            toast.success(data.message);
            queryClient.invalidateQueries('getAllAwards')
        },
    });
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete - {rowNameData}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Award will be deleted from both Resort and Brand websites. Are you sure you want to delete it?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} >
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={() => handleArchive(rowData)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteAwardsModal;
