import React from "react";
import { getCookie } from "../dataService/DataService";
import NewDashboard from "./NewDashboard";
import dashboardShot from '../../assets/images/analytics-dash-screeshot.jpg'

const AnalyticsDashboard = () => {
  // Get website id
  const websiteId = getCookie("websiteId") ? getCookie("websiteId") : 0;
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  return (
    <>
      {propertyId && propertyId !== "null" ? (
        <>
          {/* Render the NewDashboard when propertyId is valid */}
          <NewDashboard websiteId={websiteId}/>
        </>
      ) : (
        <>
          {/* Show the overlay if propertyId is null or not available */}
          <div className="page-body position-relative">
            <div className="placeholder-wrap overflow-hidden">
              <div className="dashboard-overlay-placeholder position-fixed d-flex justify-content-center align-items-center">
                <h1 className="mb-0 text-white fw-light opacity-75 text-center px-lg-5 px-3 lh-base">
                  Google Analytics has not been configured for this website. <br className="d-md-block d-none"/>You can reach out to Digitech for setting it up.
                </h1>
              </div>
              <img className="w-100" src={dashboardShot} alt="" />
              </div>
            </div>
        </>
      )}
    </>
  );
};

export default AnalyticsDashboard;
