import React from "react";
import Table from "../../components/Table2";
import { getLogs } from "../dataService/DataService";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import moment from "moment";
// import { data } from "jquery";
function Log() {
  const navigate = useNavigate();

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };

  const user = getCookie("user");

  if (user == "" || user == null) {
    navigate("/");
  }

  const Logs = useQuery({ queryKey: ["getLogs"], queryFn: getLogs });
  const { error, status } = Logs;
  

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      {
        Header: "Level",
        accessor: "level",
        Cell: ({ row }) => `${row.original.level}`,
      },
      {
        Header: "message",
        accessor: "message",
        Cell: ({ row }) => `${row.original.message}`,
      },
      {
        Header: "api",
        accessor: "api",
        Cell: ({ row }) => `${row.original.api}`,
      },
      {
        Header: "username",
        accessor: "username",
        Cell: ({ row }) => `${row.original.username}`,
      },
      {
        Header: "timestamp",
        accessor: "timestamp",
        Cell: ({ row }) => (
          <span className="" style={{ width: "200px" }}>
            {/* {moment.utc(parseInt(row.original.timestamp)).format("YYYY-MM-DD HH:mm:ss A [GMT]Z ")} */}
            {moment.utc(parseInt(row?.original?.timestamp)).add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DD HH:mm:ss A [GMT]+5:30")}
          </span>
        ),
      },
    ],
    []
  );

  const headers = ["Sr. No", "Email", "Created At"];
  

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Error Logs</h4>
            </div>
          </div>

          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">ERROR LOGS</h5>
              {/* {newsletterData.data && (
                <CSVFileDownload
                  data={newsletterData?.data?.data?.data?.map((item, index) => [
                    index + 1,
                    item.email,
                    moment(item.last_requested_on).format(
                      "MMM D, YYYY h:mm A [GMT]Z"
                    ),
                  ])}
                  headers={headers}
                  filename="newsletter.xlsx"
                />
              )} */}
            </div>

            {Logs.data && (
              <Table
                data={Logs?.data?.data?.data}
                key={Logs?.data?.data?.data.getLogs}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Log;
