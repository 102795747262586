import React from "react";
import { FaFileSignature } from "react-icons/fa";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { HiNewspaper } from "react-icons/hi2";
import { MdLocalOffer, MdVilla } from "react-icons/md";
import CardSpinner from "./CardSpinner";
function WebsiteEngagementsStats({ tileData = {}, isLoading = false }) {
  const { villaIntent, offerIntent, newsletterIntent, enqueriesIntent } =
    tileData;

  const villaIntentCardText = "Visitors interested in villas";
  const offerIntentCardText = "Visitors interested in offers";
  const newsletterIntentCardText = "Visitors who signed up for newsletter";
  const enqueriesIntentCardText = "Visitors who sent enquiries";

  const tealBg = "bg-enqr text-success";
  const tealBgTwo = "bg-nwsltr text-danger";
  const tealBgThree = "bg-offr text-primary";
  const tealBgFour = "bg-vlla text-warning";

  return (
    <>
      <ul className="list-unstyled mb-0 p-0">
        
        <EngagementCard
            isLoading={isLoading}
            statIcon={<FaFileSignature />}
            iconBg={tealBgFour}
            title={enqueriesIntentCardText}
            value={enqueriesIntent}
          />
          <EngagementCard
            isLoading={isLoading}
            statIcon={<HiNewspaper />}
            iconBg={tealBgThree}
            title={newsletterIntentCardText}
            value={newsletterIntent}
          />
        <EngagementCard
            isLoading={isLoading}
            statIcon={<MdLocalOffer />}
            iconBg={tealBgTwo}
            title={offerIntentCardText}
            value={offerIntent}
          />
         <EngagementCard
            isLoading={isLoading}
            statIcon={<MdVilla />}
            iconBg={tealBg}
            title={villaIntentCardText}
            value={villaIntent}
          />
      </ul>
    </>
  );
}

function EngagementCard({ isLoading, iconBg, statIcon, title, value }) {
  const prevValue = value?.prev ?? 0; // Set prevValue to 0 if it's undefined

  return (
    <li className="border-bottom pt-3" key={title}>
      {isLoading ? (
        <CardSpinner/>
      ) : (
        <div className="d-flex align-items-center">
          <div className="left-stats-engage pe-2 d-flex align-items-start">
            <span
              className={`staticon rounded-3 d-flex justify-content-center align-items-center mt-1 ${iconBg}`}
            >
              {statIcon}
            </span>
            <span className="stattext ps-2">{title}</span>
          </div>
          <div className="stat-icon-side d-flex align-items-center justify-content-center rounded-3 text-center">
            <div>
              <h5 className="fw-semibold mb-0">{value?.current}%</h5>
              <div className="d-flex align-items-center justify-content-center">
                <span className="d-flex justify-content-center icnspn">
                  {prevValue > 0 ? (
                    <FaArrowUp className="me-1 increase-icon text-success" />
                  ) : prevValue < 0 ? (
                    <FaArrowDown className="me-1 decrease-icon text-danger" />
                  ) : null}{" "}
                  {/* No arrow if prevValue is 0 */}
                </span>
                <small>{prevValue}%</small>
              </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

export default WebsiteEngagementsStats;
