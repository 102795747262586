import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as querydata from "../../services/service";
import { useMutation, useQuery, useQueryClient } from "react-query";
export const awardsSchema = Yup.object({
    name: Yup.string().trim().required("Please enter award name"),
    awardee: Yup.string().trim().required("Please enter awardee"),
    year: Yup.number().required("Please select year"),
});
const EditAwards = ({ showEditModal, closeEditModal, years, editId, setShowEditModal, selectedWebsiteId }) => {

    const id = editId;
    const queryClient = useQueryClient();
    const getSingleAward = useQuery(
        ["SingleAward", id],
        () => querydata.getsingleAward(id, selectedWebsiteId)
    );

    const awardsData = getSingleAward?.data?.data;
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        name: awardsData && awardsData.length > 0 ? awardsData[0].name : "",
        awardee: awardsData && awardsData.length > 0 ? awardsData[0].awardee : "",
        year: awardsData && awardsData.length > 0 ? awardsData[0].year : ""
    };
    useEffect(() => {
        setValues({ ...initialValues });
    }, [awardsData]);



    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setValues

    } = useFormik({
        initialValues: initialValues,
        validationSchema: awardsSchema,
        onSubmit: (values, action) => {


            updateprojectMutation.mutate({ id, values });
        }

    })
    const updateprojectMutation = useMutation(querydata.updateAward, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);

            toast.dismiss();
            toast.success(data.message);
            setShowEditModal(false);

            queryClient.invalidateQueries("getAllAwards");
        },
        onSettled: () => {
            setIsLoading(false);
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });
    return (
        <div>
            {/* START  DIALOG MODAL */}

            <Modal show={showEditModal} onHide={closeEditModal} centered>
                <Modal.Header closeButton className="px-5 py-4">
                    <Modal.Title as="h1" className="fs-5">
                        Edit Awards
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-5">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="name" className="form-label">
                                Award Name:
                            </Form.Label>
                            <textarea
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="name"
                                className="form-control py-3 shadow-none px-4"
                                cols="30"
                                rows="3"
                                maxLength={120}
                            ></textarea>

                        </Form.Group>

                        {errors.name && touched.name ? (
                            <p className="form-error text-danger">{errors.name}</p>
                        ) :
                            null}


                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="awardee">Awardee:</Form.Label>
                            <Form.Control
                                type="text"
                                id="awardee"
                                className="py-3 shadow-none px-4"
                                name="awardee"
                                value={values.awardee}
                                onChange={handleChange}
                                maxLength={70}
                            />
                            {errors.awardee && touched.awardee ? (
                                <p className="form-error text-danger">{errors.awardee}</p>
                            ) :
                                null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="year">Year:</Form.Label>
                            <select
                                className="form-control py-3 shadow-none px-4"
                                id="year"
                                name="year"
                                value={values.year}
                                onChange={handleChange}
                            >
                                <option value="">---Select---</option>
                                {years.map((num) => (
                                    <option key={num} value={num}>
                                        {num}
                                    </option>
                                ))}
                            </select>
                            {errors.year && touched.year ? (
                                <p className="form-error text-danger">{errors.awardYear}</p>
                            ) :
                                null}
                        </Form.Group>
                        <div className="text-end">
                            <Button
                                variant="outline-primary"
                                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                                onClick={closeEditModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {isLoading ? (
                                    <div
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></div>
                                ) : (
                                    "SAVE"
                                )}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

            {/* END  DIALOG MODAL */}
        </div >
    )
}

export default EditAwards