import axios from "axios";
import moment from "moment/moment";

const apikey = process.env.REACT_APP_APIKEY;
const apikey1 = process.env.REACT_APP_APIKEY;

export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie is the one we are looking for
    if (cookie.startsWith(name + "=")) {
      // Return the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }
  // Return null if the cookie is not found
  return null;
};

// Usage example
const token = getCookie("token1");
// const websiteId = getCookie("websiteId") ? getCookie("websiteId") : 0;

//const token = getCookie('token1');
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  withCredentials: true,
};

export const insertOffer = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/offers/website-offer/`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllResorts = async () => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apikey}/offers/get-resorts/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getOffersResort = async () => {
  try {
    const { data } = await axios.get(`${apikey}/offers/get-resorts`, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllOffers = async () => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;

    const { data } = await axios.get(
      `${apikey}/offers/website-offers/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getSingleOffer = async (id) => {
  try {
    const offerId = id;
    const { data } = await axios.get(
      `${apikey}/offers/website-offer/${offerId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateOffer = async (updatedData) => {
  try {
    const { id, formData } = updatedData;
    const { data } = await axios.put(
      `${apikey}/offers/website-offer/${id}`,
      formData,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const deleteOffer = async (formData) => {
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/offers/website-offer/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertPressRelease = async (formdata) => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.post(
      `${apikey}/press-release/add/${websiteIds}`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllPressRelease = async () => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apikey}/press-release/list/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSinglePressRelease = async (id) => {
  try {
    const pressReleaseId = id;
    const { data } = await axios.get(
      `${apikey}/press-release/edit/${pressReleaseId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updatePressRelease = async (updatedData) => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { id, formData } = updatedData;
    const { data } = await axios.post(
      `${apikey}/press-release/edit/${websiteIds}/${id}`,
      formData,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updatePublished = async (updatedData) => {
  try {
    const { formData } = updatedData;
    const { data } = await axios.post(
      `${apikey}/press-release/updatePublished`,
      formData,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deletePressRelease = async (formData) => {
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/press-release/delete/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getContectQueryData = async (websiteIds) => {
  try {
    const { data } = await axios.get(
      `${apikey}/contact/get-enquiry-list/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllUsers = async () => {
  try {
    const { data } = await axios.get(`${apikey}/website-user/users/`, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllUserRoles = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/roles-navigation/get-all-user-roles`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertUserRole = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/roles-navigation/add-user-role`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleUserRole = async (id) => {
  try {
    const pressReleaseId = id;

    const { data } = await axios.get(
      `${apikey}/roles-navigation/get-user-role/${pressReleaseId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateUserRole = async (updatedData) => {
  try {
    const { id, values } = updatedData;
    const { data } = await axios.put(
      `${apikey}/roles-navigation/update-user-role/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteUserRole = async (formData) => {
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/roles-navigation/delete-user-role/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const changeUserRoleStatus = async (updatedData) => {
  try {
    const { id, status } = updatedData;
    const { data } = await axios.put(
      `${apikey}/roles-navigation/update-user-role-status/${id}`,
      {
        status,
      },
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getNavigation = async (id) => {
  try {
    const { data } = await axios.get(
      `${apikey}/roles-navigation/user-role-navigation/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const UpdateUserRoleNavigationPermission = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/roles-navigation/user-role-navigation`,
      { userRoleNavigationData: formdata },
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateUserIsActive = async ({ userId, statusData }) => {
  if (userId > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/website-user/toggle-user-status/${userId}`,
        statusData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const login = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/website-user/login`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const websiteList = async () => {
  try {
    const { data } = await axios.get(`${apikey}/common/website-list/`, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllWebsites = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/website-user/websites-for-homepage`,
      config
    );
    return data;
  } catch (err) {
    if (err.response.status === 404) {
      window.location.href = "/";
    }

    throw Error(err.response.data.message);
  }
};

export const getSideBarData = async (id) => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apikey}/roles-navigation/get-sidebar-navigation/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    // console.log("errro status is  : ",err.response.status)
    // console.log("errro in axios   ; ", err)
    if (err.response.status === 404) {
      // console.log("error status code   : ",err.response.status)

      window.location.href = "/";
    }

    throw Error(err.response.data.message);
  }
};
export const getSideBarDataInWelcomePage = async (id) => {
  try {
    // const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    console.log("id in dataservice");
    console.log(id);
    const { data } = await axios.get(
      `${apikey}/roles-navigation/get-sidebar-navigation/${id}`,
      config
    );
    return data;
  } catch (err) {
    console.log("err in dataservice");
    console.log(err);
    throw Error(err.response.data.message);
  }
};

export const forgotPassword = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/website-user/forgot-password`,
      formdata,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const resetPassword = async (formdata) => {
  try {
    const { data } = await axios.put(
      `${apikey}/website-user/reset-password/${formdata.token}`,
      formdata,
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertAssignedUserRole = async ({ FormDataNewArray, userId }) => {
  if (userId > 0) {
    try {
      const { data } = await axios.post(
        `${apikey}/website-user/user-assigned-roles/${userId}`,
        { userAssignedData: FormDataNewArray },
        {
          withCredentials: "true",
        },
        config
      );
      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const changePassword = async (formdata) => {
  try {
    const { data } = await axios.put(
      `${apikey}/website-user/change-password`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const data = await axios.post(`${apikey}/website-user/logout`, {}, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllMenus = async (id) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;

  try {
    const { data } = await axios.get(
      `${apikey}/menu/website-menus/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertMenu = async (formData) => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.post(
      `${apikey}/menu/website-menu/${websiteIds}`,
      formData,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleMenusss = async (menuId) => {
  try {
    const { data } = await axios.get(
      `${apikey}/menu/website-menu/${menuId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteMenu = async (formData) => {
  try {
    const menuId = formData;
    const { data } = await axios.delete(
      `${apikey}/menu/website-menu/${menuId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllPages = async () => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apikey}/page/website-pages/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAspectRatio = async (offer_aspect_ratio) => {
  try {
    // const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apikey}/common/aspect-ratio/${offer_aspect_ratio}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const changePublishPressReleaseStatus = async (updatedData) => {
  try {
    const { id, isPublished } = updatedData;
    const { data } = await axios.put(
      `${apikey}/press-release/toggle-is-published/${id}`,
      {
        isPublished,
      },
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSectionByPageId = async (pageId) => {
  try {
    const id = pageId;
    const { data } = await axios.get(
      `${apikey}/page/website-section/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getSinglePageData = async (pid) => {
  if (pid > 0) {
    try {
      const page_id = pid;
      const { data } = await axios.get(
        `${apikey}/page/website-page/${page_id}`,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const updatePageData = async ({ pid, values }) => {
  if (pid > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/page/website-page/${pid}`,
        values,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const getPageSectionDetails = async (pageSectionId) => {
  try {
    const id = pageSectionId;
    const { data } = await axios.get(
      `${apikey}/page/page-section-detail/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getPageSubSectionDetails = async (pageSectionId) => {
  try {
    const id = pageSectionId;
    const { data } = await axios.get(
      `${apikey}/page/pages-subsection-detail/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updatemenuIndex = async (values) => {
  try {
    const { newSortingIndex, menuId } = values;

    const { data } = await axios.put(
      `${apikey}/menu/change-sorting-index/${menuId}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertPropertiesData = async (formData) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (websiteIds > 0) {
    try {
      const { data } = await axios.post(
        `${apikey}/website-property/add-property/${websiteIds}`,
        formData,
        config
      );

      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const getSingleProperty = async (id) => {
  try {
    const propertyId = id;
    const { data } = await axios.get(
      `${apikey}/website-property/get-property/${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateProperty = async ({ id, formData }) => {
  const propertyId = id;
  if (propertyId > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/website-property/update-property/${propertyId}`,
        formData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const insertAmenity = async (formData) => {
  try {
    const { data } = await axios.post(
      `${apikey}/website-property/amenity`,
      formData,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleAmenityData = async (amenityId) => {
  if (amenityId > 0) {
    try {
      const { data } = await axios.get(
        `${apikey}/website-property/amenity/${amenityId}`,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updateAmenityData = async ({ formData, amenityId }) => {
  if (amenityId > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/website-property/amenity/${amenityId}`,
        formData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const deleteSingleAmenity = async (amenityId) => {
  if (amenityId > 0) {
    try {
      const { data } = await axios.delete(
        `${apikey}/website-property/amenity/${amenityId}`,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const insertpageSubSection = async (formdata) => {
  try {
    const { data } = await axios.post(
      `${apikey}/page/pages-subsection-detail`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deletepageSubSection = async (formData) => {
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/page/pages-subsection-detail/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllRestaurants = async () => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.get(
      `${apikey}/dinin/restaurants/${websiteIds}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getSingleRestaurant = async (id) => {
  try {
    const data = await axios.get(`${apikey}/dinin/restaurant/${id}`, config);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteRestaurant = async (formData) => {
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/dinin/restaurant/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getRestaurantType = async () => {
  try {
    const { data } = await axios.get(`${apikey}/dinin/restaurant-type`, config);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getRestaurantTags = async () => {
  try {
    const { data } = await axios.get(`${apikey}/dinin/restaurant-tag`, config);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const deleteAlbumData = async (albumId) => {
  if (albumId > 0) {
    try {
      const { data } = await axios.delete(
        `${apikey}/media/media-album/${albumId}`,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const insertRestaurantData = async (formdata) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (websiteIds > 0) {
    try {
      const { data } = await axios.post(
        `${apikey}/dinin/restaurant/${websiteIds}`,
        formdata,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updateRestaurant = async ({ id, formData }) => {
  const restaurantId = id;
  if (restaurantId > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/dinin/restaurant/${restaurantId}`,
        formData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const updateOfferIsActive = async ({ id, status }) => {
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/offers/toggle-offer-status/${id}`,
        { status },
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updateSubSection = async ({
  pageSubSectionId,
  subSectionTitle,
}) => {
  if (pageSubSectionId > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/page/edit-subsection-details/${pageSubSectionId}`,
        { subSectionTitle },
        config
      );

      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updateSortingTable = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/website-property/update-sorting-index/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateDiningSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/dinin/update-sorting-index/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateRestaurantIsActive = async ({ id, status }) => {
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/dinin/toggle-restaurant/${id}`,
        { status },
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updatePagesSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/page/update-sorting-index/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateOfferSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/offers/update-sorting-index/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updatePressReleaseSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/press-release/update-sorting-index/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getNewsLetter = async (websiteIds) => {
  try {
    const response = await axios.get(
      `${apikey}/newsletter/get/${websiteIds}`,
      config
    );
    return response;
  } catch (err) {
    throw Error(err);
  }
};

export const updatePropertyIsActive = async ({ id, status }) => {
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/website-property/toggle-property/${id}`,
        { status },
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const optmiseImage = async (base64Image) => {
  try {
    console.log("functionis ruhdf");
    const data = await axios.post(
      `${apikey}/media/optimise-image`,
      {
        imageBlob: base64Image,
      },
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const cropImage = async (base64Image) => {
  try {
    // console.log("coordinate ;  ", coordinate);
    // console.log("dimensiion ;  ", dimension);

    const data = await axios.post(
      `${apikey}/media/crop-image`,
      {
        image: base64Image.base64,
        crop: {
          x: base64Image.coordinate.scalex,
          y: base64Image.coordinate.scaley,
          height: base64Image.dimension.height,
          width: base64Image.dimension.width,
        },
      },
      config
    );

    return data;
  } catch (err) {
    console.log("error is  : ", err);
    throw Error(err.response.data.message);
  }
};

export const getSlug = async (websiteId) => {
  try {
    const data = await axios.get(`${apikey}/menu/all-slugs/${websiteId}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getLogs = async () => {
  try {
    const data = await axios.get(`${apikey}/get/logs`, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getActivityLogs = async () => {
  try {
    const data = await axios.get(`${apikey}/get/activity`, config);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllAwards = async (selectedAward) => {
  const websiteCookie = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const websiteIds = selectedAward ? selectedAward : websiteCookie;
    const { data } = await axios.get(
      `${apikey}/awards/getawards/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getsingleAward = async (id) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const awardId = id;
    const { data } = await axios.get(
      `${apikey}/awards/single/${awardId}/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteAward = async (value) => {
  const id = value.data;
  const websiteIds = value.websiteIds;

  try {
    const { data } = await axios.delete(
      `${apikey}/awards/delete/${id}/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const updateAwardsIsActive = async ({ id, status, WebsiteId }) => {
  // const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/awards/toggle/${id}/${WebsiteId}`,
        { status },
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const updateAwardSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/awards/changesortingindex/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllCollaterals = async () => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.get(
      `${apikey}/collateral/getcollaterals/${websiteIds}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const updateCollateralsIsActive = async ({ id, status }) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apikey}/collateral/toggle/${id}/${websiteIds}`,
        { status },
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const updateCollateralSorting = async (values) => {
  try {
    const { newSortingIndex, id } = values;

    const { data } = await axios.put(
      `${apikey}/collateral/changesortingindex/${id}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllCollateralsHead = async () => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.get(
      `${apikey}/collateralhead/getcollateralshead/${websiteIds}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const deleteCollaterals = async (formData) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const id = formData;
    const { data } = await axios.delete(
      `${apikey}/collateral/delete/${id}/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const deleteCollateralsHead = async (formData) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;

  try {
    const collateralsHeadId = formData;

    const { data } = await axios.delete(
      `${apikey}/collateral/delete-head/${collateralsHeadId}/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getResortName = async () => {
  try {
    const { data } = await axios.get(`${apikey}/common/resort-list`, config);
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getUserAnalyticsData = async () => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.get(
      `${apikey}/dashboard/analytics-data`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getUserSearchAnalyticsData = async (value) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const startDate = moment(value[0]).format("YYYY-MM-DD");
    const endDate = moment(value[1] ? value[1] : new Date()).format(
      "YYYY-MM-DD"
    );

    const { data } = await axios.get(
      `${apikey}/dashboard/analytics-user-search-data?startDate=${startDate}&endDate=${endDate}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getUserSearchWidgetsAnalyticsData = async (value) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const startDate = moment(value[0]).format("YYYY-MM-DD");
    const endDate = moment(value[1] ? value[1] : new Date()).format(
      "YYYY-MM-DD"
    );

    const { data } = await axios.get(
      `${apikey}/dashboard/analytics-widget-data?startDate=${startDate}&endDate=${endDate}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// DashBorad APIS
// TrafficAcquisition api
export const getTrafficAcquisitionData = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;
  // console.log("propertyIdpropertyIdpropertyIdpropertyIdpropertyIdpropertyIdpropertyId",propertyId)
  // let rangeTypevalue=
  // console.log("startDate",startDate)
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // console.log("rangeType",rangeType)
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-users-traffic-acquisition-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
// users by devie category
export const getUsersByDeviceCategory = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  console.log("rangeType", rangeType);
  console.log("startDate", startDate);
  console.log("endDate", endDate);
  // console.log("first",sdfsd)
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-users-by-deviceCategory-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// get sessions by search engines
export const getSessionbySearchEnginesData = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-session-manual-source-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// get top countries data
export const getTopCountriesData = async (rangeType, startDate, endDate) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-top-countries-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getTopPagesData
export const getTopPagesData = async (rangeType, startDate, endDate) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-top-pages-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getAnalyticswidgetData
export const getAnalyticswidgetData = async (rangeType, startDate, endDate) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-widget-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getGoogleSearchClickandImpressionsData
export const getGoogleSearchClickandImpressionsData = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-users-clicks-impressions-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getGeographicalDistribution
export const getGeographicalDistribution = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-geographical-distribution-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getNewAndTotalUsersByDate
export const getNewAndTotalUsersByDate = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  // const rangeType="last7days";
  // const startDate="";
  // const endDate="";
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-new-total-users-datewise-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getActiveUsersByGenderAndCountry
export const getActiveUsersByGenderAndCountry = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-active-users-genderwise-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}&country=${country}`,
      config
    );

    console.log("data",data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getActiveUsersByAgeAndCountry
export const getActiveUsersByAgeAndCountry = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }

  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-active-users-agewise-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}&country=${country}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// getActiveUsersByAgeAndCountrydata
export const getActiveUsersByAgeAndCountrydata = async (
  rangeType,
  startDate,
  endDate
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    console.log("Dates are valid");
    rangeType = "";
  }

  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-active-users-agewise-country-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//new analytics api
export const getPageCountryAndWidgetData = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    rangeType = "";
  }

  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-page-country-widget-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllUserData = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    rangeType = "";
  }

  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-all-users-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}&country=${country}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getGoogleSearchData = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    rangeType = "";
  }

  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-google-search-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}&country=${country}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAgeAndCountryData = async (
  rangeType,
  startDate,
  endDate,
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  // const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    rangeType = "";
  }
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-age-country-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSessionAndCountryData = async (
  rangeType,
  startDate,
  endDate,
  selectedCountry
) => {
  // const propertyId = "388320826";
  const propertyId = getCookie("propertyId") ? getCookie("propertyId") : 0;

  const country = selectedCountry;
  if (
    startDate &&
    endDate &&
    startDate !== "undefined" &&
    endDate !== "undefined"
  ) {
    rangeType = "";
  }
  try {
    const { data } = await axios.get(
      `${apikey1}/dashboard/analytics-session-country-data?startDate=${startDate}&endDate=${endDate}&rangeType=${rangeType}&propertyId=${propertyId}&country=${country}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
