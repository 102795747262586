import 
{React, useState, useEffect } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

 function MultipleImageAltTag({
    showModalImageTagModal,
    handleCloseImageTagModal,
    initialImageAltTag="",
    tagType,
    handleSaveImageAltTag,
    inputName // dynamic input name
}) {
    const [inputValue, setInputValue] = useState(initialImageAltTag);
    console.log("initialImageAltTag")
    console.log(initialImageAltTag)
    // Function to handle input change
    const handleChange = (event) => {
        const { value } = event.target;
        setInputValue(value);
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        handleSaveImageAltTag(tagType, inputValue); // Pass tagType along with the input value
    };

    const handleCloseModal = () => {
         setInputValue('')
        handleCloseImageTagModal(false)
    }; 
    // Update the value if initialImageAltTag changes
    useEffect(() => {
        setInputValue(initialImageAltTag);
    }, [initialImageAltTag,showModalImageTagModal]);

    return (
        <Modal show={showModalImageTagModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton className="px-5 py-4">
                <Modal.Title as="h1" className="fs-5">
                    Image Alt Tag
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5 py-5">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4">
                        <Form.Label htmlFor={inputName}>Tag</Form.Label>
                        <Form.Control
                            type="text"
                            id={inputName}
                            className="py-3 shadow-none px-4"
                            name={inputName}
                            value={inputValue}
                            onChange={handleChange}
                            maxLength={160}
                        />
                    </Form.Group>
                    <div className="text-end mt-5">
                        <Button
                            variant="outline-primary"
                            className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                            type="submit"
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default MultipleImageAltTag;
