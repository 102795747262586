import React from 'react';
import { Chart } from 'react-google-charts';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(minMax);

const calculateRange = (data) => {
  const dates = data.map(item => dayjs(item.date));
  const minDate = dayjs.min(dates);
  const maxDate = dayjs.max(dates);
  return maxDate.diff(minDate, 'day');
};

const transformDataToDates = (data) => {
  return data
    .map(({ date, newUsers, totalUsers }) => [dayjs(date).format('D MMM'), newUsers, totalUsers])
    .sort((a, b) => dayjs(a[0], 'D MMM').isAfter(dayjs(b[0], 'D MMM')) ? 1 : -1);
};

const LineChartComponent = ({ data }) => {
  const range = calculateRange(data);
  const transformedData = transformDataToDates(data);

  return (
    <div>
      <Chart
        width={'100%'}
        height={'300px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Date', 'New Users', 'Total Users'],
          ...transformedData
        ]}
        
        options={{
          hAxis: {
            title: 'Date',
            showTextEvery: Math.ceil(range / 10), // Skip days on the X-axis based on range
            slantedText: true, // Optional: Slant text to fit more dates
          },
          vAxis: { title: 'Number of Users' },
          legend: 'top',
          tooltip: { isHtml: true },
          pointSize: 5, // Show nodes for each data point
          colors: ['#987BEC','#69D3AD'],
        }}
      />
    </div>
  );
};

export default LineChartComponent;
