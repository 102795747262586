import axios from "axios";
import { ListGroup } from "react-bootstrap";
// const apikey = process.env.REACT_APP_APIKEY;
const apiKey = process.env.REACT_APP_APIKEY;

const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie is the one we are looking for
    if (cookie.startsWith(name + "=")) {
      // Return the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }
  // Return null if the cookie is not found
  return null;
};

// Usage example
const token = getCookie("token1");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  withCredentials: true,
};

export const getMediaFolder = async (websiteId) => {
  try {
    const { data } = await axios.get(
      `${apiKey}/media/get-media-folder/${websiteId}`,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getAllMediaFolder = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/media/get-all-media-folder/`,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getMediaAlbumByWebId = async (websiteIds) => {
  try {
    const { data } = await axios.get(
      `${apiKey}/media/media-albums/${websiteIds}`,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getFileByAlbumId = async (albumId, formdata) => {
  try {
    const { data } = await axios.post(
      `${apiKey}/media/album-files/${albumId}`,
      formdata,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const insertAlbumFile = async ({ formdata, albumId }) => {
  try {
    const { data } = await axios.post(
      `${apiKey}/media/album-filess/${albumId}`,
      formdata,
      {
        withCredentials: "true",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const insertMediaAlbumName = async ({ websiteId, formData }) => {
  try {
    const newAlbumData = { newAlbumName: formData };
    const { data } = await axios.post(
      `${apiKey}/media/media-album/${websiteId}`,
      newAlbumData,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.data.message);
  }
};

export const updateMediaAlbumName = async ({ mediaAlbumId, formData }) => {
  try {
    const newAlbumData = { newAlbumName: formData };
    const { data } = await axios.put(
      `${apiKey}/media/media-album/${mediaAlbumId}`,
      newAlbumData,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getMediaAlbumNameById = async (selectedMediaAlbumId) => {
  if (selectedMediaAlbumId > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/media/get-single-media-album/${selectedMediaAlbumId}`,
        config
      );
      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const getImageDataByMediaFileId = async (selectedMediaFileId) => {
  if (selectedMediaFileId > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/media/album-file/${selectedMediaFileId}`,
        config
      );
      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const updateImageAndDetails = async ({ formData, fileId }) => {

  if (fileId > 0) {
    try {
      const { data } = await axios.post(
        `${apiKey}/media/album-file/${fileId}`,
        formData,
        {
          withCredentials: "true",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const deleteAlbumImg = async (deleteImgByFileId) => {
  if (deleteImgByFileId > 0) {
    try {
      const { data } = await axios.delete(
        `${apiKey}/media/album-file/${deleteImgByFileId}`,

        config
      );
      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const filterFileByAlbumId = async (albumId) => {
  if (albumId > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/media/all-album-files/${albumId}`,
        config
      );

      return data;
    } catch (error) {
      throw Error(error.response.message);
    }
  }
};

export const getSortByData = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/media/media-file-types/`,
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const insertUser = async (formData) => {
  try {
    const { data } = await axios.post(
      `${apiKey}/website-user/user`,
      formData,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteUser = async (id) => {
  try {
    const { data } = await axios.delete(
      `${apiKey}/website-user/user/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleUser1Data = async (userId) => {
  if (userId > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/website-user/user/${userId}`,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const updateUser = async ({ userId, formData }) => {
  if (userId > 0) {
    try {
      const { data } = await axios.put(
        `${apiKey}/website-user/user/${userId}`,
        formData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};




export const getUserAssignedByUserId = async (selectedUserId) => {
  if (selectedUserId > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/website-user/user-assigned-roles/${selectedUserId}`,

        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};


export const getAllProperties = async (brandResortId) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (websiteIds > 0) {
    try {
      const { data } = await axios.get(
        `${apiKey}/website-property/properties/${websiteIds}`,
        {},
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const getAllPropertiesTypes = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/website-property/property-type/`,
      {},
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllBedType = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/website-property/bed-type/`,
      {},
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const deleteProperty = async (id) => {

  try {
    const { data } = await axios.delete(
      `${apiKey}/website-property/delete-property/${id}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllAmenities = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/website-property/amenities/`,
      {},
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getRestaurantType = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/dinin/restaurant-type/`,
      {},
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const insertAward = async (value) => {

  const websiteIds = value.websiteIds;
  try {
    const { data } = await axios.post(
      `${apiKey}/awards/insertawards/${websiteIds}`,
      value,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const updateAward = async ({ id, values }) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apiKey}/awards/update/${id}/${websiteIds}`,
        values,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const getsingleAward = async (id, selectedWebsiteId) => {
  // const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const awardId = id;
    const { data } = await axios.get(
      `${apiKey}/awards/single/${awardId}/${selectedWebsiteId}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const insertCollateralHead = async (value) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.post(
      `${apiKey}/collateral/insertcollateralshead/${websiteIds}`,
      value,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const editCollateralsHead = async ({ editId, values }) => {
  try {


    const { data } = await axios.put(
      `${apiKey}/collateral/update-collateral-head/${editId}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const insertCollateral = async (formdata) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const { data } = await axios.post(
      `${apiKey}/collateral/insertcollaterals/${websiteIds}`,
      formdata,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getsingleColData = async (id) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  try {
    const collateralsId = id;
    const { data } = await axios.get(
      `${apiKey}/collateral/getsinglecollaterals/${collateralsId}/${websiteIds}`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const updateColDetail = async ({ id, formData }) => {
  const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
  if (id > 0) {
    try {
      const { data } = await axios.put(
        `${apiKey}/collateral/updatecollaterals/${id}/${websiteIds}`,
        formData,
        config
      );
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};
export const updateCollateralHeadSorting = async (values) => {
  try {
    const { newSortingIndex, collateralsHeadId } = values;
    const { data } = await axios.put(
      `${apiKey}/collateral/changesortingheadindex/${collateralsHeadId}`,
      values,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};