import React from 'react';
import { Chart } from 'react-google-charts';

const DonutChart = ({ chartData,options }) => {
  // Transforming channelData to Google Charts format
 

 

  return (
    <Chart
      chartType="PieChart"
      data={chartData}
      options={options}
      width={'100%'}
      height={'250px'}
    />
  );
};

export default DonutChart;
