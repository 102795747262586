import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import * as querydata from "../../services/service";
import { useFormik } from "formik";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import pdfImg from "../../assets/images/pdf-doc.svg";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from 'react-toastify';
export const collateralsSchemaedit = Yup.object({
    title: Yup.string().trim().required("Please enter title"),
    description: Yup.string().trim().required("Please enter description"),
});
function EditCollaterals({ showEditModal, closeEditModal, editColDetail, setEditColDetail, heading }) {

    const id = editColDetail;
    const queryClient = useQueryClient();
    const getSingleCollateralData = useQuery(
        ["getsingleColData", id],
        () => querydata.getsingleColData(id)
    );
    const collateralData = getSingleCollateralData?.data?.data;

    const [editId, setEditId] = useState("");
    const [mediaType, setMediaType] = useState("");
    const [unitPlanPdf, setUnitPlanPdf] = useState(null);
    const [singleCropImageProp, setSingleCropImageProp] = useState(null);
    const [singleFileProperties, setSingleFileProperties] = useState(null);
    const [cropMediaModal, setCropMediaModal] = useState(false);
    const closeCropMediaModal = () => setCropMediaModal(false);
    const [settingValue, setSettingValue] = useState("")
    const [folderId, setFolderId] = useState("");
    const [albumId, setAlbumId] = useState("");
    const [websiteId, setwebsiteId] = useState("");
    const [collateralpdf, setCollateralPdf] = useState(null);
    const [checkMultipleImageSection, setMultipleImageSection] = useState("");
    const [currentFileId, setCurrentFileId] = useState("");
    const [maincroppedFile, setmainCroppedFile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const showCropMediaModal = (id, val, index) => {
        setCurrentFileId(id);

        if (val === "secondImage") {
            setMultipleImageSection(val);
            setSettingValue("1/1");
        }
        setCropMediaModal(true);
    };
    const handleMainCroppedImage = (e) => {
        setmainCroppedFile(null);
        if (e) {
            setCollateralPdf(null);
        }
    }

    const initialValues = {
        title: collateralData && collateralData.length > 0 ? collateralData[0].title : "",
        description: collateralData && collateralData.length > 0 ? collateralData[0].description : ""
    }
    useEffect(() => {
        setValues({ ...initialValues });
        const originalUrl = collateralData?.[0]?.pdf_url;
        console.log(originalUrl, "originalUrl")


        setCollateralPdf(originalUrl);
        setmainCroppedFile(originalUrl);
    }, [collateralData]);
    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        setValues

    } = useFormik({
        initialValues: initialValues,
        validationSchema: collateralsSchemaedit,
        onSubmit: (values, action) => {
            console.log(values);
            const formData = new FormData();
            if (heading === null || heading === "") {

                toast.dismiss();
                toast.error("Please add head first");

                return;
            } else {
                formData.append("heading", heading);
            }
            formData.append("title", values.title);
            formData.append("description", values.description);
            if (collateralpdf === null || collateralpdf === false) {

                toast.dismiss();
                toast.error("Please upload a pdf");

                return;
            } else {
                formData.append("pdflink", collateralpdf);
            }
            updateprojectMutation.mutate({ id, formData });

        }
    })
    const updateprojectMutation = useMutation(querydata.updateColDetail, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            closeEditModal();
            toast.dismiss();
            toast.success(data.message);


            queryClient.invalidateQueries("getAllCollaterals");
        },
        onSettled: () => {
            setIsLoading(false);
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });

    return (
        <div>
            <Modal show={showEditModal} onHide={closeEditModal} centered>
                <Modal.Header closeButton className="px-5 py-4">
                    <Modal.Title as="h1" className="fs-5">
                        Edit Collaterals
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-5">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="title">Title</Form.Label>
                            <textarea

                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="title"
                                className="form-control py-3 shadow-none px-4"
                                cols="30"
                                rows="3"
                                maxLength={70}
                            ></textarea>
                            {errors.title && touched.title ? (
                                <p className="form-error text-danger">{errors.title}</p>
                            ) :
                                null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="description">Description</Form.Label>
                            <textarea

                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="description"
                                className="form-control py-3 shadow-none px-4"
                                cols="30"
                                rows="3"
                                maxLength={70}
                            ></textarea>
                            {errors.description && touched.description ? (
                                <p className="form-error text-danger">{errors.description}</p>
                            ) :
                                null}
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label htmlFor="pdf">Upload Pdf</Form.Label>
                            {!collateralpdf && (
                                <div className="uploaded-property-img d-flex form-control p-0">
                                    <label
                                        htmlFor="UploadCover"
                                        className="upload-label position-relative w-100 rounded-3 px-4 py-5"
                                        onClick={() =>
                                            showCropMediaModal("", "secondImage")
                                        }
                                    >
                                        <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                            <Button
                                                type="button"
                                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                            >
                                                Choose pdf from gallery
                                            </Button>
                                        </div>
                                    </label>
                                </div>
                            )}
                            {collateralpdf && (

                                <div
                                    className="uploaded-property-img d-flex form-control p-0"
                                    style={{
                                        // height: "200px",
                                        // width: "200px",
                                        height: "125px",
                                        width: "141px",
                                        position: "relative",
                                    }}
                                >
                                    <div className="ml-3">
                                        <a href={collateralpdf} target="_blank">
                                            <img
                                                src={
                                                    collateralpdf?.type === "application/pdf"
                                                        ? pdfIcon
                                                        : pdfIcon
                                                }

                                                style={
                                                    collateralpdf?.type === "application/pdf"
                                                        ? {
                                                            width: "100px",
                                                            // height: "130%",
                                                            objectFit: "cover",
                                                        }
                                                        : {
                                                            width: "100px",
                                                            // height: "130%",
                                                            // objectFit: "cover",
                                                        }
                                                }
                                            />
                                        </a>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                right: "0",
                                            }}
                                        >
                                            <button
                                                className="remove-image-button"
                                                onClick={(e) => handleMainCroppedImage(e)}
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </Form.Group>
                        <div className="text-end">
                            <Button
                                variant="outline-primary"
                                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                                onClick={closeEditModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {isLoading ? (
                                    <div
                                        class="spinner-border text-primary"
                                        role="status"
                                    ></div>
                                ) : (
                                    "SAVE"
                                )}
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>
            {/* image modal */}
            <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
                <Modal.Header className="modal-header px-5 py-4" closeButton>
                    <Modal.Title className="modal-title fs-5">
                        {"Select Image"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" overflow-x-auto overflow-y-auto">
                    {!folderId && <MediaFolderView setFolderId={setFolderId} />}
                    {folderId && !albumId && !websiteId && (
                        <ManageAlbumSelection
                            folderId={folderId}
                            setAlbumId={setAlbumId}
                            setwebsiteId={setwebsiteId}
                            setFolderId={setFolderId}
                        />
                    )}
                    {folderId && albumId && websiteId && (

                        <AlbumViewSelectionForProperties
                            albumId={albumId}
                            websiteId={websiteId}
                            setAlbumId={setAlbumId}
                            setwebsiteId={setwebsiteId}
                            dataCommingFrom="addCollaterals"
                            setFolderId={setFolderId}
                            setCropMediaModal1={setCropMediaModal}
                            // maincroppedImageForCol={collateralpdf}
                            // setmainCroppedImageForCol={setCollateralPdf}
                            checkMultipleImageSectionForCol={checkMultipleImageSection}
                            setmainCroppedFileForCol={setmainCroppedFile}
                            setSingleCropImageProp={setSingleCropImageProp}
                            setUnitPlanPdf={setUnitPlanPdf}
                            setting_value={settingValue}
                            mediaTypeCode={mediaType}
                            setMediaType={setMediaType}
                            setSingleFileProperties={setCollateralPdf}
                            checkValForSingleImg={"singleUnitPlanImage"}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditCollaterals