import React from 'react'

function TableWithThead({tableTheadData=[],tableRowDataItem=[]}) {
    console.log("tableRowDataItem",tableRowDataItem)
  return (
    <>
        <div className='table-responsive'>
            <table className='table table-borderless mb-0'>
                <thead>
                    <tr>
                        {tableTheadData?.map((theaditem,index) =>(
                            <th key={index}>
                                {theaditem?.tableTheadName}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {/* const tableTheadData = [
        { tableTheadName: 'Search Engine' },
        { tableTheadName: 'Users' },
        { tableTheadName: 'Sessions' },
        { tableTheadName: 'Eng. Sessions' },
        { tableTheadName: 'Eng. rate' }
    ] */}
                {tableRowDataItem?.data?.slice(0, 3)?.map((item, index) => (
                    <tr key={index} className='bottom-border'>
                        <td>{item?.sessionManualSource}</td>
                        <td>{item?.activeUsers}</td>
                        <td>{item?.sessions}</td>
                        <td>{item?.engagementSession}</td>
                        <td>{item?.engagementRate}</td>
                    </tr>
                ))}
                    {/* {tableRowDataItem.map((item, rowIndex) => (
                    <tr className='bottom-border' key={rowIndex}>
                        {item.tableTbodyData.map((data,index) =>(
                            data.withImageData ? (
                                <td key={index}>
                                    <img src={data.searchWebLogo} alt='' />
                                    <span className='ms-3 smalltxt'>{data.searchWebName}</span>
                                </td>
                            )
                            :
                            (
                                <td key={index}>
                                    {data.searchWebName}
                                </td>
                            )
                        ))}
                    </tr>
                    ))} */}
                </tbody>
            </table>
        </div>
    </>
  )
}

export default TableWithThead