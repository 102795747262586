import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import * as querydata from "../pages/dataService/DataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const resetPasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Please enter your old password"),
  nPassword: Yup.string()
    .required("Please enter a password")
    .notOneOf(
      [Yup.ref("oldPassword"), null],
      "Old Password and New Password not to be same."
    )
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?~`[\]\\';,./]).{8,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character, and be at least 8 characters long"
    ),
  cPassword: Yup.string()
    .oneOf([Yup.ref("nPassword"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const ChangePasswordModal = ({
  showModal,
  handleShowModal,
  handleCloseModal,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const initialValues = {
    oldPassword: "",
    nPassword: "",
    cPassword: "",
  };

  const handleModalClose = () => {
    // Reset form values when the modal is closed
    setOldPassword(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
    handleReset(); // Reset the form using formik's resetForm function
    handleCloseModal();
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleOldPassword = (e) => {
    e.preventDefault();
    setOldPassword(!oldPassword);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, action) => {
      resetPasswordMutation.mutate({ ...values });
      action.resetForm();
    },
  });

  // Form post
  const resetPasswordMutation = useMutation(querydata.changePassword, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success("New password has been created successfully");
      handleCloseModal();
    },
  });

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header className="px-5 py-4" closeButton>
        <Modal.Title className="modal-title fs-5">Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-5 py-5">
        <Form onSubmit={handleSubmit}>
          <Form.Group
            className="position-relative mb-4"
            controlId="newPassword"
          >
            <Form.Label className="form-label">Old Password</Form.Label>
            <div className="form-group">
              <Form.Control
                maxLength={20}
                type={oldPassword ? "text" : "password"}
                name="oldPassword"
                placeholder="Enter old password"
                value={values.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                className="form-control py-3 shadow-none px-4"
              />
              <span
                className="showpassword position-absolute"
                onClick={toggleOldPassword}
              >
                <FontAwesomeIcon icon={oldPassword ? faEye : faEyeSlash} />
              </span>
              {errors.oldPassword && touched.oldPassword && (
                <div className="text-danger">{errors.oldPassword}</div>
              )}
            </div>
          </Form.Group>

          <Form.Group
            className="position-relative mb-4"
            controlId="confirmPassword"
          >
            <Form.Label className="form-label">New Password</Form.Label>
            <div className="form-group">
              <Form.Control
                maxLength={20}
                type={showPassword ? "text" : "password"}
                placeholder="Enter new password"
                name="nPassword"
                value={values.nPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                className="form-control py-3 shadow-none px-4"
              />
              <span
                className="showpassword position-absolute"
                onClick={toggleShowPassword}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
              {errors.nPassword && touched.nPassword && (
                <div className="text-danger">{errors.nPassword}</div>
              )}
            </div>
          </Form.Group>

          <Form.Group
            className="position-relative mb-4"
            controlId="confirmPassword"
          >
            <Form.Label className="form-label">Confirm New Password</Form.Label>
            <div className="form-group">
              <Form.Control
                maxLength={20}
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm new password"
                name="cPassword"
                value={values.cPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                className="form-control py-3 shadow-none px-4"
              />
              <span
                className="showpassword position-absolute"
                onClick={toggleConfirmPassword}
              >
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEye : faEyeSlash}
                />
              </span>
              {errors.cPassword && touched.cPassword && (
                <div className="text-danger">{errors.cPassword}</div>
              )}
            </div>
          </Form.Group>

          <div className="text-end mt-4">
            <Button
              variant="secondary"
              className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
              onClick={handleSubmit}
            >
              Change Password
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
