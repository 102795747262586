
import React, { useState } from "react";
import Table from "../../components/Table2";
import { getContectQueryData } from "../dataService/DataService";
import { useQuery } from "react-query";
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import moment from 'moment';


const Message = () => {

    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showMessages, setMessages] = useState("");


    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        // Return null if the cookie is not found
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");



    const allEnqueryData = useQuery(
        ["getContectQueryData", +websiteIdFromCookie],
        () => getContectQueryData(websiteIdFromCookie)
    );



    const handleViewMessageModal = (message) => {

        setMessages(message);

        setShowMessageModal(true);
    }
    const closeMessageModal = () =>
        setShowMessageModal(false);



    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },

            {
                Header: "Name",
                Cell: ({ row }) => `${row.original.name}`,
            },
            { Header: "Email", accessor: "email", Cell: ({ row }) => `${row.original.email}`, },
            { Header: "Phone Number", accessor: "phone_number", Cell: ({ row }) => `${row.original.phone}` },
            { Header: "Resort", accessor: "website", Cell: ({ row }) => `${row.original.website?row.original.website:""}` },
            { Header: "Enquiry Type", accessor: "enquery_type", Cell: ({ row }) => `${row.original.enquiry_type}` },
            {
                Header: "DateTime", accessor: "",
                Cell: ({ row }) => (moment(row.original.datetime).format('MMM D, YYYY h:mm A [GMT]Z'))
            },

            {
                Header: "Message",
                accessor: "message",
                Cell: ({ row }) => (
                    <>
                        {row.original.message.length > 20
                            ? `${row.original.message.substring(0, 20)}...`
                            : row.original.message}
                        <button
                            variant="info"
                            onClick={() => handleViewMessageModal(row.original.message)}
                            className="btn text-success fw-semibold pt-0 pb-0 ms-1"
                        >
                            View

                        </button>
                    </>
                ),
            },

        ],
        []
    );





    if (allEnqueryData.status === "loading") {
        <div>Loading</div>
    }


    if (!allEnqueryData.data) {
        <div>Loading</div>
    }
    if (allEnqueryData.isLoading) {
        <div>Loading</div>
    }
    const headerHeading = 'All Enquiries';

    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">

                        <div className="left-header">
                            <h4 className="fw-normal m-0">Enquiries</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5 mt-4">
                        {/* <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All Enquiries</h5>
                        </div> */}
                        {allEnqueryData.data &&
                            <Table data={allEnqueryData?.data?.data} key={allEnqueryData?.data?.data.enquiry_id} columns={columns} isShowingHeading={true} isExcelDownloadBtnShow={false} headerHeading={headerHeading} />
                        }

                    </div>
                </div>
            </div>


            {/* START  DIALOG MODAL */}

            <Modal show={showMessageModal} onHide={closeMessageModal}>
                <Modal.Header className="px-5 py-4" closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <p>{showMessages}</p>
                    <div className="text-end mt-4">
                        <Button className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" variant="secondary" onClick={closeMessageModal} >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* END  DIALOG MODAL */}

        </>
    );
};

export default Message;
