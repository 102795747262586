import React from 'react';
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Dropdown } from "react-bootstrap";
import { faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DraggableDropdownItem from './DraggableDropdownItem';
function CollateralDropDown({ id, items, tasks, handelHeading, handleSelectOption, headData, selectedCollateral, allCollaterals, handleRemoveTab, isDraggingWithinDropdown, dropdownVisible, open, setOpen }) {


    return (
        <>
            <SortableContext items={tasks} strategy={verticalListSortingStrategy}>

                <Dropdown show={open} >
                    <Dropdown.Toggle className="form-control form-select py-3 shadow-none px-4 text-start dropdown-to-select" id="dropdown-basic">
                        <span className='d-flex overflow-hidden colletral-drop-item' onClick={() => setOpen(!open)}>{selectedCollateral !== null ? headData?.find(item => item.head_id === selectedCollateral)?.collateral_name || "---Select Head---" : "---Select Head---"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {allCollaterals?.data?.data?.map((item) => (
                            <DraggableDropdownItem

                                id={item.head_id}
                                key={item.head_id}
                                option={item}
                                handelHeading={handelHeading}
                                handleRemoveTab={handleRemoveTab}
                                handleSelectOption={handleSelectOption}
                                isDraggingWithinDropdown={isDraggingWithinDropdown}
                            />

                        ))}
                    </Dropdown.Menu>
                </Dropdown>

            </SortableContext>
        </>
    );
}

export default CollateralDropDown;

