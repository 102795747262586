import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify';

import * as dataquery from "../dataService/DataService";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
export default function DeleteCollateralsHead({ row, show, handleClose, rowData, rowNameData }) {

    const queryClient = useQueryClient();

    const handleArchive = (data) => {
        deleteCollateralRecord.mutate(data);
        handleClose();
    };
    const deleteCollateralRecord = useMutation(dataquery.deleteCollateralsHead, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            toast.dismiss();
            toast.success(data.message);
            queryClient.invalidateQueries('getAllCollaterals')
        },
    });

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete - {rowNameData}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Once you delete head,all data related to it  will also be
                        deleted. </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} >
                        Close
                    </Button>
                    <Button variant="primary"
                        onClick={() => handleArchive(rowData)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
