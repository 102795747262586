import React from "react";
import BarChartComponent from "../../components/charts/BarChart";
import DonutChartGender from "../../components/charts/DonutChartGender";

function DemographicTabItem({
  selectedUserAgeBracketData,
  selectedUserGenderData,
}) {
  // Prepare data for the bar chart (age brackets)
  const activeUsersByAgeAndCountry = selectedUserAgeBracketData?.map((item) => ({
    ageBracket: item.ageBracket,
    activeUsers: item.activeUsers,
  }));

  // Prepare data for the donut chart (gender)
  const chartData = [
    ["Gender", "Active Users"], // Header row
    ...(Array.isArray(selectedUserGenderData)
      ? selectedUserGenderData.map((item) => [
          item.gender,
          item.activeUsers,
        ])
      : []), // Fallback to empty array if it's not iterable
  ];

  // Define chart options for DonutChartGender
  const options = {
    pieHole: 0.4, // Creates the donut hole
    chartArea: { width: "50%" },
    legend: { position: "bottom" },
    colors: [
      "#FF8C67",
      "#987BEC",
      "#FF77A0",
      "#69D3AD",
      "#76C5FF",
      "#FFBF5F",
      "#A5A9D0",
    ], // Array of colors for slices
    pieSliceText: "label", // Show labels inside slices
    pieSliceBorderColor: "white", // Border color of pie slices
  };

  return (
    <>
      <div className="d-lg-flex align-items-center justify-content-between mt-lg-4 mt-3">
        <div className="total-user-chart pe-lg-4 text-lg-start text-center">
          {activeUsersByAgeAndCountry?.length > 0 ? (
            <BarChartComponent data={activeUsersByAgeAndCountry} />
          ) : (
            <p>No age data available</p>
          )}
        </div>
        <div className="v-border d-lg-block d-none"></div>
        <div className="w-100 users-chart-view ps-lg-4 mt-lg-0 mt-4 text-lg-start text-center">
          {chartData.length > 1 ? (
            <DonutChartGender chartData={chartData} options={options} />
          ) : (
            <p>No gender data available</p>
          )}
        </div>
      </div>
    </>
  );
}

export default DemographicTabItem;
